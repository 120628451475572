<template>
  <v-card flat width="100%">
    <v-alert v-if="loading">Loading</v-alert>
    <v-card-actions class="d-print-none" v-if="!hideControls">
      <v-btn @click="showRestrictions=!showRestrictions">Show Restrictions</v-btn>
    </v-card-actions>

    <template v-if="!loading">
      <v-toolbar dense flat v-if="teamCount">
        <v-spacer/>
        <v-chip outlined>{{ teamCount }} people: {{ estimateTimeInMinutes }} minutes</v-chip>
        <!--        Team of {{teamCount}} people should take {{ formatDuration(estimateTimeInMinutes) }} for {{mealCountNoAllergy}} meals-->
      </v-toolbar>
      <v-spacer/>
      <template v-if="!hideHeader">
        <v-card-title class="text-h4">
          <v-chip class="mr-2 red--text" outlined v-if="showRestrictions">ALLERGY INFO</v-chip>
          <template v-if="meal.diet===18">
            <v-icon color="green">mdi-leaf</v-icon>
            <span style="color: darkgreen"> {{ getDietName(meal.diet) }} ({{ getTimeOfDay(meal.tod) }})</span>
          </template>
          <template v-if="meal.diet!==18">
            {{ getDietName(meal.diet) }} ({{ getTimeOfDay(meal.tod) }})
          </template>

          <template v-if="meal.plating_rack">
            <v-spacer/>
            Rack M{{ meal.plating_rack }}<span v-if="formatSummary.allergy>0">/A{{ meal.plating_rack }}</span>
          </template>
          <v-spacer/>
          {{ dateFormatted }}
        </v-card-title>

        <v-card-title class="text-h3" style="word-break: break-word">
          {{ meal.name }}
          <v-spacer/>
          <span v-if="meal.production_priority">P{{ meal.production_priority }}</span>
        </v-card-title>
      </template>

      <v-card-text v-if="showRestrictions">
        <MealRestrictionSummary
          :meal="meal"
          :orders="orders"
          only-problems
        />
      </v-card-text>

      <v-card-text>
        <v-row class="text-center">
          <v-col>
            <v-chip color="black" type="info" outlined>
              Total meals: {{ formatSummary.total }}
            </v-chip>
          </v-col>
          <v-col>
            <v-chip color="black" type="info" outlined>
              <!--              Regular + No Milk: {{ (formatSummary.total - formatSummary.allergy) + formatSummary.noMilk }}-->
              Regular: {{ (formatSummary.total - formatSummary.allergy) }}
            </v-chip>
          </v-col>
          <!--          <v-col>-->
          <!--            <v-chip color="black" type="info" outlined>-->
          <!--              Just No Milk: {{ formatSummary.noMilk }}-->
          <!--            </v-chip>-->
          <!--          </v-col>-->
          <v-col>
            <v-chip color="black" type="info" outlined>
              Allergy: {{ formatSummary.allergy }}
            </v-chip>
          </v-col>
        </v-row>
        <v-simple-table
          class="table-summary"
        >
          <template v-slot:default>

            <thead>
            <tr>
              <th class="pl-1" :class="amountHeaderClass" :style="`min-width: ${ingredientWidth}`">
              </th>
              <th :style="`min-width: ${amountWidth}`" :class="amountHeaderClass" v-if="!hideSmall">
                <template v-if="formatSummary.smallAllergy>0">
                  {{ date && formatSummary.smallRegular }}+{{ formatSummary.smallAllergy || 0 }}A
                  <br/>
                </template>
                {{ formatSummary.small }}S
              </th>
              <th :style="`min-width: ${amountWidth}`" :class="amountHeaderClass">
                <template v-if="formatSummary.mediumAllergy>0">
                  {{ date && formatSummary.mediumRegular }}+{{ formatSummary.mediumAllergy || 0 }}A
                  <br/>
                </template>
                {{ formatSummary.medium }}M
              </th>
              <th :style="`min-width: ${amountWidth}`" :class="amountHeaderClass" v-if="!hideLarge">
                <template v-if="formatSummary.largeAllergy>0">
                  {{ date && formatSummary.largeRegular }}+{{ formatSummary.largeAllergy || 0 }}A
                  <br/>
                </template>
                {{ formatSummary.large }}L
              </th>
              <th :style="`min-width: ${amountWidth}`" :class="amountHeaderClass" class="d-print-none">
                Monosize
              </th>
            </tr>
            </thead>
            <tbody
              class="plating-table-body"
              v-for="(c,componentName) of ingredientsByComponent"
              v-bind:key="c.id"
            >
            <PlatingRow
              :read-only="readOnly"
              class="platingTable"
              :title="getNameAndBatch(c.id,componentName)"
              :subtitle="Object.entries(formatSummary).join(' ')"
              :component="components[c.id] || {}"
              :ingredients="c.ingredients"
              :dense="dense"
              :font-size="smaller?'font-weight-bold':'text-large'"
              :hide-small="hideSmall"
              :hide-large="hideLarge"
            />
            <tr v-if="showRestrictions" class="">
              <!--              <td/>-->
              <td colspan="5" class="">
                <MealRestrictionSummary
                  :meal="meal"
                  :orders="orders"
                  :selected-component-id="c.id"
                  only-problems
                />
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr>
              <td :class="tableFontSize">Container</td>
              <td :class="tableFontSize + ' text-large'" style="text-align: right" v-if="!hideSmall">{{ containerWeight }} g</td>
              <td :class="tableFontSize + ' text-large'" style="text-align: right">{{ containerWeight }} g</td>
              <td :class="tableFontSize + ' text-large'" style="text-align: right" v-if="!hideLarge">{{ containerWeight }} g</td>
              <td/>
            </tr>
            <tr>
              <td :class="tableFontSize">Total Weight</td>
              <td :class="tableFontSize + ' text-large'" style="text-align: right" v-if="!hideSmall">{{
                  formatWeight(cookedAmountTotals.small + containerWeight)
                }} g
              </td>
              <td :class="tableFontSize + ' text-large'" style="text-align: right">
                {{ formatWeight(cookedAmountTotals.medium + containerWeight) }} g
              </td>
              <td :class="tableFontSize + ' text-large'" style="text-align: right" v-if="!hideLarge">{{
                  formatWeight(cookedAmountTotals.large + containerWeight)
                }} g
              </td>
              <td/>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-dialog
        fullscreen
        v-model="showComponentDialog"
      >
        <MealToComponent
          :ingredientsByComponent="ingredientsByComponent"
          v-on:close="showComponentDialog=false"
          v-on:closeAndSave="createComponentAndMoveIngredients($event)"
        />
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import MealToComponent from '@/components/MealToComponent';
import PlatingRow from '@/components/PlatingRow';
import moment from 'moment';
import {ensureNumber, getProductionDays} from "@/store/utils";
import MealRestrictionSummary from "@/components/MealRestrictionSummary";
import urlState from "@/router/urlState";


export default {
  components: {
    MealRestrictionSummary,
    MealToComponent,
    PlatingRow
  },
  mixins: [urlState],
  props: {
    readOnly: Boolean,
    mealId: {},
    teamCount: {},
    date: String,
    ordersIn: {type: Array, default: null}, // prevents fetching orders
    dense: Boolean,
    smaller: Boolean,
    hideHeader: {type: Boolean, default: null, required: false},
    hideControls: {type: Boolean, default: null, required: false},
    restrictions: Boolean,
    skipUrlSync: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      loading: false,
      showComponentDialog: false,
      amountWidth: '105px', //this.dense ? '20px;' : '60px',
      ingredientWidth: '50px', //this.dense ? '' : '*',
      amountClass: this.dense ? 'px-1 py-0 my-0 text-right' : '',
      amountHeaderClass: 'text-large text-right',
      containerWeight: 26,
      showRestrictions: false,
      tableFontSize: 'text-large',
      componentBatches: {}
    }
  },
  async created() {
    await this.fetchData();
    if (!this.skipUrlSync) {
      this.syncToUrl({
        param: 'showRestrictions', urlParam: 'restrictions', initFromRoute: true,
        parseCallback: (v) => v === 'true'
      });
    }
  },
  mounted() {
    if (this.smaller) {
      this.amountHeaderClass = 'text-right';
      this.tableFontSize = 'font-weight-bold';
    }
    if (this.restrictions !== undefined) {
      this.showRestrictions = this.restrictions
    }
  },
  watch: {
    mealId() {
      // this.fetchData();
    }
  },
  computed: {
    ...mapGetters([
      'components',
      'getComponentName',
      'getDietName',
      'getTimeOfDay',
      'getOrdersOnDate'
    ]),
    hideSmall(){
      return this.formatSummary.total>0 && this.formatSummary.small===0;
    },
    hideLarge(){
      return this.formatSummary.total>0 && this.formatSummary.large===0;
    },
    productionKey() {
      return getProductionDays(this.date).join('-');
    },
    orders() {
      if (this.ordersIn) {
        return this.ordersIn;
      }
      const mealId = Number(this.mealId);
      return this.getOrdersOnDate({date: this.date}).filter(m => m.meal_id === mealId);
    },
    activeIngredients() {
      return this.ingredients.filter(i => !i.toDelete);
    },
    meal() {
      return this.$store.getters.getMeal(this.mealId) || {};
    },
    // summary() {
    //   if (this.date) {
    //     return this.getSummaryForDiet(this.date, this.meal.diet, this.meal.tod)
    //   } else {
    //     return {small: '', medium: '', large: '', total: 0};
    //   }
    // },
    ingredientsByComponent() {
      const result = {};
      // console.log('this.com', this.components);
      this.ingredients.forEach(i => {
        const componentName = this.getComponentName(i.component_id);
        result[componentName] = result[componentName] || {
          id: i.component_id,
          ingredients: []
        };
        result[componentName].ingredients.push(i);
      });
      return result;
    },
    cookedAmountTotals() {
      const sum = (array) => array.reduce((ac, i) => ac + ensureNumber(i), 0);
      const result = {small: 0, medium: 0, large: 0};
      Object.values(this.ingredientsByComponent)
        .forEach(({id, ingredients}) => {
          const component = this.components[id];
          // console.log('cooked total', {id, component, ingredients});
          const calculateCookedAmountTotal = sum(
            ingredients
              .filter(i => !i.ignore_prep_weight)
              .filter(i => !i.toDelete)
              .map(d => d.cookedAmount)
          );
          result.medium += calculateCookedAmountTotal;
          if (component.monosized) {
            result.small += calculateCookedAmountTotal;
            result.large += calculateCookedAmountTotal;
          } else {
            result.small += calculateCookedAmountTotal * component.small_ratio;
            result.large += calculateCookedAmountTotal * component.large_ratio;
          }
        });
      return result;
    },
    ingredients() {
      const ingredients = this.$store.getters.getMealIngredients(this.mealId) || [];
      ingredients.sort((a, b) => {
        const componentNameA = a.component_name || 'ZZZ';
        const componentNameB = b.component_name || 'ZZZ'
        if (componentNameA !== componentNameB) {
          return componentNameA.localeCompare(componentNameB);
        }
        return (a.fooddescriptionsimple || a.fooddescription || '').localeCompare(b.fooddescriptionsimple || b.fooddescription || '');
      });
      // console.log('ingredients', ingredients.map(i => [i.fooddescriptionsimple, i.component_name].join('-')));
      return ingredients;
    },
    formatSummary() {
      const sums = this.orders.reduce((sums, o) => {

        sums[o.meal_size] = (sums[o.meal_size] || 0) + o.quantity;
        sums.total += o.quantity;
        if (!o.allergyDetected) {
          const key = `${o.meal_size}Regular`;
          sums[key] = (sums[key] || 0) + o.quantity;
        } else {
          const key = `${o.meal_size}Allergy`;
          sums.allergy += o.quantity;
          // splitting out no milk
          // if (o.allergyDetected.milk && Object.keys(o.allergyDetected).length === 1) {
          //   sums['noMilk'] = (sums['noMilk'] || 0) + o.quantity;
          //   sums[`${o.meal_size}NoMilk`] = (sums[`${o.meal_size}NoMilk`] || 0) + o.quantity;
          // } else
          {
            sums[key] = (sums[key] || 0) + o.quantity;
          }
        }
        return sums;
      }, {small: 0, medium: 0, large: 0, total: 0, noMilk: 0, allergy: 0});
      return sums;
    },
    mealCountNoAllergy() {
      return this.orders
        .filter(o => !o.allergyDetected)
        .reduce((sum, o) => sum + o.quantity, 0);
    },
    estimateTimeInMinutes() {
      const minutesPerMeal = 60 / 40;  // 40 meals per hour
      const result = this.teamCount
        ? ((minutesPerMeal * this.mealCountNoAllergy) / this.teamCount).toFixed(1)
        : '';
      return result;
    },
    dateFormatted() {
      return moment(this.date).format('dddd MMM DD');
    },
  },
  methods: {
    ...mapActions([
      'fetchMeal',
      'fetchMealIngredients',
      'fetchOrders',
      'fetchDiets',
      'createComponent',
      'updateIngredient',
      'updateComponent',
      'replaceIngredientFood',
      'fetchOrdersByDate',
      'fetchComponentBatches'
    ]),
    formatWeight(value) {
      if (value === undefined || Number.isNaN(value)) {
        // console.log('warning formatWeight called with ', value);
        return '0';
      }
      value = Number.parseFloat(value);
      // console.log('formatWeight', [value, value.toFixed()]);
      // return value;
      return value.toFixed(0);
    },
    fetchData() {
      // console.log('creating detail for ', this.mealId);
      this.loading = true;
      this.$emit('is-loading', true);
      return Promise.all([
        this.fetchMeal(this.mealId),
        this.fetchMealIngredients({id: this.mealId}),
        this.ordersIn || this.fetchOrdersByDate({date: this.date}),
        this.fetchDiets(),
        this.fetchComponentBatches({mealId: this.mealId, productionKey: this.productionKey})
          .then(r => this.componentBatches = r)
      ])
        .finally(() => {
          this.loading = false;
          this.$emit('is-loading', false);
          // console.log('finished loading', this.mealId, false)
        });
    },
    addIngredient(component_id) {
      const componentName = this.getComponentName(component_id);
      this.ingredients.push({
        component_id,
        component_name: componentName,
        fooddescriptionsimple: '',
        editing: true,
        mealid: this.meal.id,
        portion_multiplier: 0,
        portion_basis: 1455
      });
      console.log('this.ingredients', this.ingredients);
    },
    async createComponentAndMoveIngredients({component_name, monosized, ingredientsToMove}) {
      console.log('creating component', {component_name, monosized, ingredientsToMove})
      let emptyComponent = true;
      let {id} = await this.createComponent({name: component_name, monosized});
      this.ingredients.forEach(i => {
        if (ingredientsToMove[i.foodid]) {
          emptyComponent = false;

          this.replaceIngredientFood({
            oldIngredient: i,
            newIngredient: {
              ...i,
              component_id: id
            }
          });

          // this.$set(i, 'component_id', id);
          // this.$set(i, 'component_name', component_name);
          // console.log('adding to component', [component_name, i.foodid, i.fooddescriptionsimple]);
        }
      });
      // this.components[component_id]
      console.log('this.ingredients', this.ingredients.filter(i => ingredientsToMove[i.foodid]));
      if (emptyComponent) {
        this.addIngredient(id);
      }
      this.showComponentDialog = false;
    },
    formatDuration(minutes) {
      return moment.duration(minutes, 'minutes').humanize();
    },
    getNameAndBatch(id, componentName) {
      const {componentBatches} = this;
      if (componentBatches[id]) {
        return `${componentName} [${componentBatches[id]}]`;
      } else {
        return componentName
      }
    }
  }
}
</script>

<style>

.text-large {
  font-size: 28px !important;
}

.table-summary .v-text-field__slot input {
  text-align: right;
}

.plating-table-body tr:nth-of-type(odd) {
  /*background-color: rgba(0, 0, 0, .05);*/
}


</style>
